import React from 'react';
import Img from "gatsby-image"
import LayoutContainer from '../components/shared/layoutContent';

// import buildLogo from '../assets/buildpartner.jpeg';

function Hero({ title, techStack, image, logo, location, heroImage, svg }) {
  return (

    <section className="hero is-primary is-medium ">
      <div className="hero-body main-container">
      <LayoutContainer>

        <div className="columns">
          <div className="column vertical-center">
            <div className="container content-container">

              {logo ? <figure className="image company-image">
                <Img alt="hero" fluid={image.fluid} imgStyle={{
                  objectFit: "contain",
                }} />
              </figure> : svg && title==='Slutty Beef Burger' ? <img className="felix-logo" alt="heoo" src={svg} /> : null}

              <h1 className="title">{title}</h1>
              <h2 className="subtitle">{techStack}</h2>
         
            </div>
          </div>
          {
            heroImage ?
          <div className="column vertical-center">
          <div className="main-image-container vertical-center">
            <img
              src={heroImage}
              alt="logo"
              className="logo"
            />
          </div>
        </div>: null
          }

        </div>

        </LayoutContainer>


      </div>

    </section>
  );
}

export default Hero;
