import React from "react"
import Hero from '../components/Hero';
import Layout from "../components/shared/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import T from '../components/T';

const TermsAndConditions = ({data}) => {
    const {childContentfulCompetitionTermsAndConditionsTermsAndConditionsRichTextNode} = data.allContentfulCompetitionTermsAndConditions.edges[0].node
    const content = [childContentfulCompetitionTermsAndConditionsTermsAndConditionsRichTextNode]
   return ( 
    <Layout>
        <SEO title="Competition T and C's" />
        <Hero
            title="Competition T and C's"
            techStack=""
        />
        {content.map((item, index) => {
              return (
                <T key={index} item={item} />
              )
         })}
      
    </Layout>
   )}

export default TermsAndConditions

export const query = graphql`
query{
    allContentfulCompetitionTermsAndConditions {
        edges {
          node {
            id
            title
            childContentfulCompetitionTermsAndConditionsTermsAndConditionsRichTextNode {
              json
            }
          }
        }
  }
}`

